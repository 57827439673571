import React from 'react';
import inyx from './lc-03.jpg'
class Inyx extends React.Component {
  state = {

  }
  render() {
   return (
     <div style={{textAlign:'center',backgroundColor:'#f6f9fd'}}>
       <img src={inyx} style={{ width:'1440px', backgroundColor:'#fff'}} alt='' />
     </div>
   ) 
  }
}
export default Inyx