import React, { Component } from 'react';
import { Tabs } from 'antd';
import './App.css';
import banner from "./img/baner.jpg"
import advantage from "./advantage@3x.png"
import mission from "./mission@3x.png"
import object from "./object@3x.png"
import staff from "./img_rlzy.png"
import cpzx from "./img_cpzx@3x.png"
import LOGO from './img/logo.jpg'

import BOX1 from './img/box/box1.jpg'
import BOX2 from './img/box/box2.jpg'
import BOX3 from './img/box/box3.jpg'
import BOX4 from './img/box/box4.jpg'

import WATER1 from './img/water/water1.jpg'
import WATER2 from './img/water/water2.jpg'
import WATER3 from './img/water/water3.jpg'

import EYEWRAP1 from './img/eyewrap/eyewrap1.jpg'
import EYEWRAP2 from './img/eyewrap/eyewrap2.jpg'
import EYEWRAP3 from './img/eyewrap/eyewrap3.jpg'

import CONTACTLENSES1 from './img/contactlenses/contactlenses1.jpg'
import CONTACTLENSES3 from './img/contactlenses/contactlenses3.jpg'
import CONTACTLENSES4 from './img/contactlenses/contactlenses4.jpg'
import CONTACTLENSES5 from './img/contactlenses/contactlenses5.jpg'
import NURSINGLIQUID from './img/nursingliquid/nursingliquid.jpg'

import ZS1 from './img/zs1.png'
import ZS2 from './img/zs2.png'
import ZS3 from './img/zs3.png'
import ZS4 from './img/zs4.png'

import FOOTER from './img/footer2.jpg'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      type: "box"
    }
  }

  componentDidMount() {

  }

  viewPdf = (_url) => {
    window.open(_url)
  }

  tabChange = (value) => {
    window.location.href = window.location.origin + value
  }

  tabChange1 = (value) => {
    this.setState({
      type: value
    })
  }
  onDocumentComplete(pages) {
    this.setState({ page: 1, pages: pages });
  }
  onChange(page) {
    this.setState({
      page: page,
    });
  }
  render() {
    return (
      <div className="App">
        <div className="center">
          <div className="topNav">
            <div style={{ width: 1440, margin: "auto", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <img style={{ width: '100px' }} src={LOGO} alt="" />
              </div>
              <Tabs defaultActiveKey="1" tabBarGutter={60} onChange={this.tabChange}>
                <Tabs.TabPane tab="关于我们" key="#banner"></Tabs.TabPane>
                <Tabs.TabPane tab="产品信息展示中心" key="#product"></Tabs.TabPane>
                <Tabs.TabPane tab="人力资源" key="#staff"></Tabs.TabPane>
                {/* <Tabs.TabPane tab="行业及产品资讯" key="#news"></Tabs.TabPane> */}
                <Tabs.TabPane tab="联系我们" key="#us"></Tabs.TabPane>
              </Tabs>
            </div>
          </div>
          <div id="banner" className="banner">
            <img src={banner} alt="" />
          </div>
          <div id="advantage" className="advantage">
            <img src={advantage} alt="" />
          </div>
          <div id="mission" className="mission">
            <img src={mission} alt="" />
          </div>
          <div id="object" className="advantage object">
            <img src={object} alt="" />
          </div>
          <div id="product" className="product">
            <div className="wrap">
              <div className="title" style={{ width: "477px", height: 142, marginBottom: 30, position: "relative" }}>
                <span style={{ position: "absolute", top: "22px", left: "18%", fontSize: 38, color: "#0060c4", fontWeight: "bold" }}>产品信息展示中心</span>
                <img src={cpzx} alt="" />
              </div>
              <div style={{ width: 250, margin: "auto", textAlign: "center", position: "relative" }}>
                <div style={{ position: "absolute", left: "-70%", top: "20%" }}>
                  <Tabs defaultActiveKey="box" tabBarGutter={60} onChange={this.tabChange1}>
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>伴侣盒</span>} key="box"></Tabs.TabPane>
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>洗眼液</span>} key="water"></Tabs.TabPane>
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>蒸汽眼罩</span>} key="eyewrap"></Tabs.TabPane>
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>隐形眼镜</span>} key="contactlenses"></Tabs.TabPane>
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>护理液</span>} key="nursingliquid"></Tabs.TabPane>
                  </Tabs>
                </div>
              </div>
              <div style={{ width: "1250px", margin: "auto", height: 500, marginTop: 120 }}>
                <ul style={{ display: "flex", justifyContent: "space-around" }}>
                  {
                    this.state.type === "box" ? (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={BOX1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            3N眼镜四代隐形眼镜清洗器
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={BOX2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            eyekan新款凯达HL-988隐形眼镜清洗器
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={BOX3} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            凯达小黄鸭卡通隐形眼镜盒
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={BOX4} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            美尼康硬性隐形眼镜ITH双头镜盒伴侣盒
                          </div>
                        </li>
                      </>
                    ) : null
                  }
                  {
                    this.state.type === "water" ? (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={WATER1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            LaPeche拉拜诗樱桃小丸子联名洗眼液
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={WATER2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            拉拜诗LaPeche维生素B12洗眼液
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={WATER3} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            日本科奈美维生素B12洗眼液
                          </div>
                        </li>
                      </>
                    ) : null
                  }
                  {
                    this.state.type === "eyewrap" ? (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={EYEWRAP1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            LaPeche拉拜诗无香热敷蒸汽眼罩
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={EYEWRAP2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            眸论5片装香味热敷蒸汽眼罩
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={EYEWRAP3} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            眸论春日娃娃5片装香味热敷蒸汽眼罩
                          </div>
                        </li>
                      </>
                    ) : null
                  }
                  {
                    this.state.type === 'contactlenses' ? (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={CONTACTLENSES1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            LaPeche拉拜诗美瞳伞语日抛
                          </div>
                        </li>
                        <a href='/inyx' target='_blank' style={{ width: 284, height: 380, display: 'inline-block' }}>
                          <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", cursor: 'pointer' }} >

                            <div className="img" style={{ width: 188, height: 188 }}>
                              <img src={CONTACTLENSES5} alt="" style={{ width: 188, height: 188 }} />
                            </div>
                            <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                              iNYX艾尼丝美瞳
                            </div>

                          </li>
                        </a>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={CONTACTLENSES3} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            LaPeche拉拜诗伞语小直径美瞳月抛
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={CONTACTLENSES4} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            拉拜诗LaPeche隐形近视透明眼镜半年抛
                          </div>
                        </li>
                      </>
                    ) : null
                  }
                  {
                    this.state.type === 'nursingliquid' ? (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={NURSINGLIQUID} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            LaPeche拉拜诗×樱桃小丸子30年联名隐形眼镜护理液
                          </div>
                        </li>
                      </>
                    ) : null
                  }
                </ul>
              </div>
            </div>
          </div>
          <div id="staff" className="staff">
            <img src={staff} alt="" />
          </div>
          <div id="us" className="us" style={{ position: "relative", }}>
            <div>
              <img src={FOOTER} alt="" />
              <a href={ZS1} className='zs_code' target='_blank' rel="noopener noreferrer" style={{ position: 'absolute', left: '56.6%', bottom: '10.1%', zIndex: 1, color: '#fff' }} >（沪）-非经营性-2021-0165</a>
              {/* <span style={{ fontSize: 16, position: "absolute", top: "88%", left: "40%", color: "#fff" }}>互联网药品信息服务资格证书编号：</span> */}
              <div className='zs' style={{ position: "absolute", width: '100%', top: "92%",  color: '#fff', fontSize: '16px', textAlign: 'center' }} >
                <a href="https://beian.miit.gov.cn/" target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >备案号：沪ICP备2021013322号-1</a>
                &nbsp;|&nbsp;
                <a href={ZS2} target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >医疗器械经营许可证：沪宝食药监械经营许20180053号</a>
                &nbsp;|&nbsp;
                <a href={ZS3} target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >医疗器械网络销售备案信息</a>
                &nbsp;|&nbsp;
                <a href={ZS4} target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >经营者信息</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
